import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core'
import CommentBox from 'components/CommentBox';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textField: {
    width: '100%',
  },
}));

const CommentDialog = ({ open, onClose, cardContent }) => {
  const classes = useStyles();
  const [comment, setComment] = useState('');

  // Handle change for the comment input
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      {/* <DialogTitle>Comment</DialogTitle> */}
      <DialogContent>
        {/* Display the card content inside the dialog */}
       {cardContent}
      </DialogContent>
      {/* Dialog actions with full-width TextField */}
      <DialogActions>
       <CommentBox/>
      </DialogActions>
    </Dialog>
  );
};

export default CommentDialog;
