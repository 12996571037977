import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	login: (username, tenant, password) => {
		return Axios.post(
			API_ENDPOINTS.LOGIN,
			{ email: username, password, tenant_name: tenant },
			{
				headers: {
					Authorization: 'Bearer ' + btoa(`${username}:${password}`),
					'Content-Type': 'application/json',
				},
			}
		)
	},
	logout: () => {
		return Axios.post(API_ENDPOINTS.LOGOUT, {refresh_token:''}, { headers: { isAuthRequired: true } })
	},
	fetchProfile: (cancelSource) => {
		return Axios.get(API_ENDPOINTS.PROFILE, { cancelToken: cancelSource.token, headers: { isAuthRequired: true } })
	},
	changeCurrentUserPassword: (formData) => {
		return Axios.put(API_ENDPOINTS.USER_CHANGE_PASSWORD_API, formData, {
			headers: { isAuthRequired: true, 'Content-Type': 'application/json' },
		})
	},
	setUserHomeDashboard: (dashboardId, config_landing) => {
		return Axios.put(
			API_ENDPOINTS.USER_HOME_CONFIG,
			{ dash_id: dashboardId, config_landing: config_landing },
			{ headers: { isAuthRequired: true, 'Content-Type': 'application/json' } }
		)
	},
}
