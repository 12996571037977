
 
import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import reduxPromiseMiddleWare from 'redux-promise-middleware'
import {thunk} from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './services/sagas'
import reducers from './services/reducers'

const sagaMiddleWare = createSagaMiddleware()

const store = createStore(reducers, applyMiddleware(sagaMiddleWare, thunk, reduxPromiseMiddleWare, createLogger({ collapsed: true })))

sagaMiddleWare.run(rootSaga)

export default store
