
import React from 'react'
import { Formik } from 'formik'

const Validator = (props) => {
	return (
		<Formik
			initialValues={{ username: '', password: '', tenant_name: '' }}
			validate={(values) => {
				let errors = {}

				if (!values.username) {
					errors.username = 'Please enter your Email Address'
				} else {
					if (!/\S+@\S+\.\S+/.test(values.username)) errors.username = 'The Email Address is incorrect'
				}
				// if (!values.tenant_name) {
				// 	errors.tenant_name = 'Please enter a Tenant'
				// }
				if (!values.password) {
					errors.password = 'Please enter the Password'
				}
				return errors
			}}
			onSubmit={props.onSubmit || null}
		>
			{props.children}
		</Formik>
	)
}

export default Validator
