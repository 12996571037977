import React from 'react';
import { Typography, Grid, Box } from '@material-ui/core';

const LikeDislikeComment = ({ likeCount, dislikeCount, commentCount }) => {
  return (
    <Box display="flex" justifyContent="space-between" style={{padding:10}} width="97%">
      {/* Likes and dislikes with no space between */}
      <Box display="flex">
        <Typography variant="body1" color="textSecondary">
          <span style={{ fontWeight: 'bold' }}>{likeCount}</span> likes
        </Typography>
        <Typography variant="body1" color="textSecondary" style={{ marginLeft: 3 }}>
          <span style={{ fontWeight: 'bold' }}>{dislikeCount}</span> dislikes
        </Typography>
      </Box>

      {/* Comments count aligned to the right */}
      <Typography variant="body1" color="textSecondary">
        <span style={{ fontWeight: 'bold' }}>{commentCount}</span> comments
      </Typography>
    </Box>
  );
};

export default LikeDislikeComment;
