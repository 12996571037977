import { Icon, makeStyles, Grid, IconButton, Box, Typography, Button, ImageList, ImageListItem, useMediaQuery, useTheme } from '@material-ui/core'
import React, { useEffect, useMemo, useState, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import _ from 'lodash'
import Popup from './Popup'

// Import Swiper styles
import 'swiper/swiper.scss'
import ReactPlayer from 'react-player'
import { getImgUrl } from 'utils'
import classnames from 'classnames'
import VideocamIcon from '@material-ui/icons/Videocam'
import YouTubeIcon from '@material-ui/icons/YouTube'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import FilePreviewDialog from './FilePreviewDialog'
import { readFileAsDataURL } from 'utils'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'

const useStyles = makeStyles((theme) => ({
	swiperRoot: {
		height: '100%',
		marginTop: '16px',
		'& .MuiIcon-fontSizeSmall': {
			fontSize: '2.25rem',
		},
	},
	slideContent: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		padding: '5px',
		boxSizing: 'border-box',
	},
	slideImage: {
		maxHeight: '90vh',
		maxWidth: '100%',
	},
	mobileStepper: {
		width: '100%',
		borderRadius: '10px',
	},
	navBtn: {
		color: 'rgba(0, 0, 0, 0.54)',
	},
	noPrevRoot: {
		backgroundColor: '#4c494c',
		color: theme.palette.common.white,
		// borderRadius: '10px',
		textAlign: 'center',
		height: 'inherit',
		alignContent: 'center',
	},
	downloadBtn: {
		marginTop: theme.spacing(2),
	},
	attachmentView: {
		// display: 'flex',
		// flexWrap: 'wrap',
		// justifyContent: 'space-around',
		// overflow: 'hidden',
		// backgroundColor: theme.palette.background.paper,

		padding: '16px 16px 0px 16px',
	},
	imageList: {
		// width: 500,
		// height: 450,

		width: '100%',
		height: 'auto',
	},
	noThumbnaillist: {
		height: '500px !important',
		maxHeight: '500px !important',
		minHeight: '200px !important',
	},
	singleImageItem: {
		height: 'auto !important',
		maxHeight: '500px !important',
		minHeight: '200px !important',
	},
	imageListItem: {
		// this style is for image & video in feed view should take their own reslution
		height: 'auto !important',
		maxHeight: '350px !important',
		minHeight: '200px !important',
		// image in original revolution with background colour
		// '& .MuiImageListItem-item': {
		// 	display: 'flex',
		// 	alignItems: 'center',
		// 	justifyContent: 'center',
		// 	backgroundColor: '#4f4b4b',
		// },
	},
	otherFile: {
		height: '100%',
		display: 'block',
		overflow: 'hidden',
		position: 'relative',
		alignContent: 'center',
		borderRadius: '5px',
	},
	imageComponent: {
		marginTop: '0.8rem',
		boxSizing: 'inherit',
		display: 'block',
	},
	relative: {
		position: 'relative !important',
	},
	imageContainer: {
		height: '552px',
		display: 'flex',
		flexWrap: 'wrap',
		width: '100%',
		position: 'relative',
	},
	image: {
		// height: 'calc(100% - 2px)',
		// width: 'calc(100% - 2px)',
		// image in original revolution with background colour
		// objectFit: 'contain',
		// show image in its original quaity (it may zoom view)
		height: '100%',
		width: '100%',
		objectFit: 'cover',
	},
	noThumbnails: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '1px',
		left: '1px',
		height: 'calc(100% - 2px)',
		width: 'calc(100% - 2px)',
		backgroundImage: `linear-gradient(rgb(49 46 46), rgb(12 12 12))`,
	},
	videoContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '1px',
		left: '1px',
		height: 'calc(100% - 2px)',
		width: 'calc(100% - 2px)',
		backgroundImage: `linear-gradient(rgb(255 255 255 / 0%), rgb(255 249 249 / 0%))`,
	},
	moreContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		top: '1px',
		left: '1px',
		height: 'calc(100% - 2px)',
		width: 'calc(100% - 2px)',
		backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))`,
	},
	emptyThumbnail: {
		backgroundImage: `linear-gradient(rgb(53 47 47), rgb(47 32 32))`,
	},
	videocamIcon: {
		fontSize: '40px',
		color: '#fafafb',
		backgroundColor: '#ff5722',
		borderRadius: '8px',
	},
	audioIcon: {
		fontSize: '40px',
		color: '#fafafb',
		backgroundColor: 'rgb(29, 233, 182)',
		borderRadius: '8px',
	},
	youtubeIcon: {
		color: '#ff0000',
		fontSize: '65px',
	},
	moreCount: {
		fontSize: '32px',
		color: 'white',
		position: 'absolute',
		textShadow: '2px 1px 4px black',
	},
	videoMoreCount: {
		fontSize: '32px',
		color: 'white',
		position: 'absolute',
		textShadow: '2px 1px 4px black',
	},
}))

const isImage = (mediaType) => {
	return ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'].indexOf(mediaType) > -1
}

const isPlayerSupport = (mediaType) => {
	return (
		[
			'video/mp4',
			'video/3gpp',
			'video/wmv',
			'video/mkv',
			'video/webm',
			'video/avi',
			'video/avchd',
			'video/flv',
			'video/swf',
			'video/f4v',
			'video/mpeg-2',
			'video/x-ms-wmv',
			'application/octet-stream',
		].indexOf(mediaType) > -1
	)
}

const isAudioSupport = (mediaType) => {
	return ['audio/mpeg', 'audio/mp3', 'audio/3gp', 'audio/flac', 'audio/cda', 'audio/wav'].indexOf(mediaType) > -1
}

const isYoutubeVideo = (mediaType) => {
	return ['url'].indexOf(mediaType) > -1
}

const imgExtensions = ['jpeg', 'png', 'jpg', 'gif']

const SwiperCarousel = ({ fileList, attachments_count, accessToken, sticky, sticky_title }) => {
	const classes = useStyles()
	const theme = useTheme()
	const [swiper, setSwiper] = useState(null)
	const [activeIdx, setActiveIdx] = useState(0)

	const [filePrevs, setFilePrevs] = useState([])

	const [{ openFilePrevDialog, openFileIdx }, setFilePrevState] = useState({ openFilePrevDialog: false, openFileIdx: 0 })

	useEffect(() => {
		Promise.all(
			_.map(fileList, async (file) => {
				const isLocalFile = file instanceof File
				let fileName = _.get(file, isLocalFile ? 'name' : 'file_name', '')
				let fileExt = _.isEqual(_.get(file, 'file_type'), 'url') ? 'url' : _.split(fileName, '.').pop()
				return {
					ext: fileExt,
					source: isLocalFile
						? _.indexOf(imgExtensions, fileExt.toLowerCase()) > -1
							? await readFileAsDataURL(file)
							: ''
						: getImgUrl(accessToken, _.get(file, 'file_path'), 80),
					name: fileName,
					isLocalFile,
					subTitle: isLocalFile ? 'New file' : _.get(file, 'created_date', ''),
					fileSize: parseInt(_.get(file, 'file_size', 0)),
				}
			})
		).then((filePrevList) => {
			setFilePrevs(filePrevList)
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileList])

	const onClickFileListItem = (index) => {
		setFilePrevState({ openFilePrevDialog: true, openFileIdx: index })
	}

	const closeFilePrevDialog = () => {
		setFilePrevState({ openFilePrevDialog: false, openFileIdx: 0 })
	}

	const [open, setOpen] = React.useState(false)

	const isSmartPhone = useMediaQuery(theme.breakpoints.down(1000))

	const countToShow = isSmartPhone ? 4 : 5
	const lastIndex = isSmartPhone ? 3 : 4

	const handleClickOpen = (index) => {
		setActiveIdx(index)
		setOpen(true)
	}
	const handleClose = () => {
		setOpen(false)
	}

	const params = {
		onSwiper: setSwiper,
		autoHeight: true,
		slidesPerView: 1,
	}

	const handleNext = () => {
		if (!_.isEmpty(swiper)) {
			swiper.slideNext()
			setActiveIdx(activeIdx + 1)
		}
	}

	const handleBack = () => {
		if (!_.isEmpty(swiper)) {
			swiper.slidePrev()
			setActiveIdx(activeIdx - 1)
		}
	}

	const onClickDownload = (filePath) => {
		window.open(filePath, '_blank')
	}

	useEffect(() => {
		if (swiper && swiper.activeIndex !== activeIdx) {
			swiper.slideTo(activeIdx, 0)
		}
	}, [activeIdx, swiper])

	const attMobSize = (count, index) => {
		let size
		if (count === 3) {
			if (index < 1) {
				size = 2
			} else {
				size = 1
			}
		} else if (count === 1) {
			size = 2
		} else {
			size = 1
		}
		return size
	}

	const attDeskSize = (count, index) => {
		let size
		if (count === 6) {
			if (index < 2) {
				size = 1.5
			} else {
				size = 1
			}
		} else if (count === 5) {
			if (index < 2) {
				size = 1.5
			} else {
				size = 1
			}
		} else if (count === 4) {
			size = 1.5
		} else if (count === 3) {
			if (index < 1) {
				size = 3
			} else {
				size = 1.5
			}
		} else if (count === 2) {
			size = 1.5
		} else if (count === 1) {
			size = 3
		}
		return size
	}

	const filesToShow = useMemo(
		() =>
			_.map(fileList, (file) => ({
				...file,
				// width: '100%',
				// height: 'auto',
				// url: _.isEqual(file?.file_type, 'url') ? file?.file_path : getImgUrl(accessToken, file?.file_path),
				// src: isImage(file?.file_type) ? getImgUrl(accessToken, file?.file_path) : getImgUrl(accessToken, file?.thumbnail_path),
				url: _.isEqual(file?.file_type, 'url') ? file?.file_path : '',
				src: isImage(file?.file_type) ?  file?.file_path : file?.thumbnail_path,
			})),
		[fileList, accessToken]
	)

	return (
		<>
			{/* <Grid className={classes.swiperRoot} container alignItems='center' justifyContent='center'>
				<Grid item lg={1} xs={1} container justifyContent='center'>
					<IconButton className={classes.navBtn} disabled={activeIdx === 0} onClick={handleBack} size='small'>
						<Icon fontSize='small'>chevron_left</Icon>
					</IconButton>
				</Grid>
				<Grid item lg={10} xs={10}>
					<Swiper className={classes.swiperContainer} {...params}>
						{_.map(filesToShow, (file, index) => (
							<SwiperSlide key={file?.attachment_id} className={classes.slideContent}>
								{isImage(file?.file_type) ? (
									<img className={classes.slideImage} alt={'Image Loading...'} src={file?.url} />
								) : isPlayerSupport(file?.file_type) ? (
									<ReactPlayer
										className={classes.reactPlayer}
										url={file?.url}
										width={'100%'}
										height={'auto'}
										playing={false}
										controls={true}
										preload={true}
										config={{
											youtube: {
												playerVars: { showinfo: 1 },
											},
										}}
										fallback={<img src={file?.url} alt='Fallback Thumbnail' />}
									/>
								) : isYoutubeVideo(file?.file_type) ? (
									<ReactPlayer
										className={classes.reactPlayer}
										url={file?.url}
										width={'100%'}
										height={'400px'}
										playing={false}
										controls={true}
										preload={true}
										config={{
											youtube: {
												playerVars: { showinfo: 1 },
											},
										}}
										fallback={<img src={file?.url} alt='Fallback Thumbnail' />}
									/>
								) : (
									<Box paddingTop={2} paddingLeft={3} paddingBottom={2} paddingRight={3} className={classes.noPrevRoot}>
										<Typography variant='body1'>{`${file?.file_name}` || `No preview available`}</Typography>
										<Button
											startIcon={<Icon>get_app</Icon>}
											size='small'
											variant='contained'
											disableElevation
											color='primary'
											className={classes.downloadBtn}
											onClick={() => {
												onClickDownload(getImgUrl(accessToken, file?.file_path))
											}}
										>
											Download
										</Button>
									</Box>
								)}
							</SwiperSlide>
						))}
					</Swiper>
				</Grid>
				<Grid item lg={1} xs={1} container justifyContent='center'>
					<IconButton className={classes.navBtn} disabled={_.isEqual(activeIdx, fileList?.length - 1)} onClick={handleNext} size='small'>
						<Icon fontSize='small'>chevron_right</Icon>
					</IconButton>
				</Grid>
			</Grid> */}

			<div className={classes.attachmentView}>
				<ImageList rowHeight={160} className={classes.imageList} cols={isSmartPhone ? 2 : 3}>
					{filesToShow.slice(0, countToShow).map((file, index) => {
						const balCount = _.isEqual(index, lastIndex) && _.gt(attachments_count, countToShow)
						const balAtt = attachments_count - countToShow
						const currentLength = filesToShow.slice(0, countToShow).length
						return (
							<ImageListItem
								key={file?.url}
								className={classnames(
									attachments_count <= 1 &&
										(isPlayerSupport(file?.file_type) || isYoutubeVideo(file?.file_type) || isAudioSupport(file?.file_type)) &&
										_.isEmpty(file?.thumbnail_path)
										? classes.noThumbnaillist
										: attachments_count > 1
										? classes.imageListItem
										: classes.singleImageItem
								)}
								cols={isSmartPhone ? attMobSize(currentLength, index) : attDeskSize(currentLength, index) || 1}
								onClick={() => {
									// handleClickOpen(index)
									onClickFileListItem(index)
								}}
							>
								{isImage(file?.file_type) ? (
									<>
										<img className={classes.image} alt={'Image Loading...'} src={file?.src} />
										{balCount && (
											<div className={classes.moreContainer}>
												<span className={classes.moreCount}>+{balAtt}</span>
											</div>
										)}
									</>
								) : isPlayerSupport(file?.file_type) ? (
									<>
										{!_.isEmpty(file?.thumbnail_path) ? (
											<img className={classes.image} alt={'Video Loading...'} src={file?.src} />
										) : (
											<div className={classes.emptyThumbnail}></div>
										)}
										<div
											className={classnames(
												balCount ? classes.moreContainer : _.isEmpty(file?.thumbnail_path) ? classes.noThumbnails : classes.videoContainer
											)}
										>
											<VideocamIcon className={classes.videocamIcon} />
											{balCount && <span className={classes.videoMoreCount}>+{balAtt}</span>}
										</div>
									</>
								) : isAudioSupport(file?.file_type) ? (
									<>
										<div className={classes.emptyThumbnail}></div>
										<div
											className={classnames(
												balCount ? classes.moreContainer : _.isEmpty(file?.thumbnail_path) ? classes.noThumbnails : classes.videoContainer
											)}
										>
											<AudiotrackIcon className={classes.audioIcon} />
											{balCount && <span className={classes.videoMoreCount}>+{balAtt}</span>}
										</div>
									</>
								) : isYoutubeVideo(file?.file_type) ? (
									<>
										{!_.isEmpty(file?.thumbnail_path) ? (
											<img className={classes.image} alt={'Video Loading...'} src={file?.src} />
										) : (
											<div className={classes.emptyThumbnail}></div>
										)}
										<div
											className={classnames(
												balCount ? classes.moreContainer : _.isEmpty(file?.thumbnail_path) ? classes.noThumbnails : classes.videoContainer
											)}
										>
											<YouTubeIcon className={classes.youtubeIcon} />
											{balCount && <span className={classes.moreCount}>+{balAtt}</span>}
										</div>
									</>
								) : (
									<div className={classes.otherFile}>
										<Box paddingTop={2} paddingLeft={3} paddingBottom={2} paddingRight={3} className={classes.noPrevRoot}>
											<Typography variant='body1'>{`${file?.file_name}` || `No preview available`}</Typography>
											<Button
												startIcon={<Icon>get_app</Icon>}
												size='small'
												variant='contained'
												disableElevation
												color='primary'
												className={classes.downloadBtn}
												onClick={() => {
													onClickDownload(getImgUrl(accessToken, file?.file_path))
												}}
											>
												Download
											</Button>
										</Box>
										{balCount && (
											<div className={classes.moreContainer}>
												<span className={classes.moreCount}>+{balAtt}</span>
											</div>
										)}
									</div>
								)}
							</ImageListItem>
						)
					})}
				</ImageList>
			</div>

			<Popup
				setOpen={setOpen}
				open={open}
				handleClickOpen={handleClickOpen}
				handleClose={handleClose}
				filesToShow={filesToShow}
				attachments_count={attachments_count}
				accessToken={accessToken}
				fileList={fileList}
				sticky_title={sticky_title}
				sticky={sticky}
				activeIdx={activeIdx}
				setActiveIdx={setActiveIdx}
			/>

			<FilePreviewDialog
				open={openFilePrevDialog}
				accessToken={accessToken}
				openFileIdx={openFileIdx}
				fileList={fileList}
				sticky={sticky}
				onClose={closeFilePrevDialog}
			/>

			{/* <div className={classes.imageComponent}>
				<div className={classes.relative}>
					<div className={classes.imageContainer}>
						{filesToShow.map((file) => (
							<>
								{isImage(file?.file_type) ? (
									<img alt={'Image Loading...'} src={file?.url} />
								) : isPlayerSupport(file?.file_type) ? (
									<ReactPlayer
										url={file?.url}
										width={'100%'}
										height={'auto'}
										playing={false}
										controls={true}
										preload={true}
										config={{
											youtube: {
												playerVars: { showinfo: 1 },
											},
										}}
										fallback={<img src={file?.url} alt='Fallback Thumbnail' />}
									/>
								) : isYoutubeVideo(file?.file_type) ? (
									<ReactPlayer
										url={file?.url}
										width={'100%'}
										height={'400px'}
										playing={false}
										controls={true}
										preload={true}
										config={{
											youtube: {
												playerVars: { showinfo: 1 },
											},
										}}
										fallback={<img src={file?.url} alt='Fallback Thumbnail' />}
									/>
								) : (
									<Box paddingTop={2} paddingLeft={3} paddingBottom={2} paddingRight={3} className={classes.noPrevRoot}>
										<Typography variant='body1'>{`${file?.file_name}` || `No preview available`}</Typography>
										<Button
											startIcon={<Icon>get_app</Icon>}
											size='small'
											variant='contained'
											disableElevation
											color='primary'
											className={classes.downloadBtn}
											onClick={() => {
												onClickDownload(getImgUrl(accessToken, file?.file_path))
											}}
										>
											Download
										</Button>
									</Box>
								)}
							</>
						))}
					</div>
				</div>
			</div> */}
		</>
	)
}

export default SwiperCarousel
