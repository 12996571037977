import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import { logout, dismissLogOutError } from '../../services/session/actions'
import Snackbar from '@material-ui/core/Snackbar'
import Icon from '@material-ui/core/Icon'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Drawer from '../Drawer'
import logo from '../../images/logo.svg'
import favicon from '../../images/favicon.ico'
import { fetchProfile, resetProfile } from '../../services/session/actions'
import { fetchAppConfigs } from '../../services/appsettings/actions'
import _ from 'lodash'
import { Avatar, Badge, ListItemSecondaryAction } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Helmet } from 'react-helmet'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { isModuleAccessGranted, getImgUrl, stringToHslColor, addHttp, userHasAdminRole } from 'utils'
import NotificationDrawer from 'components/NotificationDrawer'
import { usePermission } from 'hooks/usePermission'

const mapStateToProps = (state) => ({
	isLoggingOut: state.session.isLoading,
	logOutError: state.session.error,
	isShowLogOutError: state.session.showLogOutError,
	user: state.session.user,
	appSettings: state.appSettings,
})

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(logout()),
	dismissLogOutError: () => dispatch(dismissLogOutError()),
	fetchProfile: () => dispatch(fetchProfile()),
	resetProfile: () => dispatch(resetProfile()),
	fetchAppConfigs: () => dispatch(fetchAppConfigs()),
})

//settings menu
const useStylesSettingsMenu = makeStyles((theme) => ({
	settingsPopper: { zIndex: 1000 },
	paper: {
		boxShadow: '0 1px 2px 0 rgba(60,64,67,0.10), 0 2px 6px 2px rgba(60,64,67,0.15)',
	},
	listItemIcoRoot: {
		minWidth: '28px',
	},
	menuIcon: {
		paddingRight: '12px',
		fontSize: '1.2em !important',
		'&.active': {
			color: `${theme.palette.primary.main}!important`,
		},
	},
	menuText: {
		fontSize: '13px',
		letterSpacing: '2px',
	},
	settingsMenuItem: {
		'&.active': {
			color: `${theme.palette.primary.main}!important`,
		},
	},
}))

const settingsMenuList = [
	// {
	// 	menuText: 'Data Connection',
	// 	moduleList: [ROLE_MODULES.DATACONNECTION],
	// 	menuIcon: 'settings_input_hdmi',
	// 	linkTo: '/dataconnection',
	// },
	// {
	// 	menuText: 'Data Source',
	// 	moduleList: [ROLE_MODULES.DATASOURCE],
	// 	menuIcon: 'dns',
	// 	linkTo: '/datasource',
	// },
	// {
	// 	menuText: 'Graphs',
	// 	moduleList: [ROLE_MODULES.GRAPHS],
	// 	menuIcon: 'insert_chart_outlined',
	// 	linkTo: '/graphs',
	// },
	// {
	// 	menuText: 'User Management',
	// 	moduleList: [ROLE_MODULES.USER_MANAGEMENT, ROLE_MODULES.ROLE_MANAGEMENT, ROLE_MODULES.TEAM_MANAGEMENT],
	// 	menuIcon: 'people',
	// 	linkTo: '/usermanagement',
	// },
	// {
	// 	menuText: 'Projects',
	// 	moduleList: [ROLE_MODULES.PROJECTS],
	// 	menuIcon: 'folder',
	// 	linkTo: '/projects',
	// },
	// {
	// 	menuText: 'Product',
	// 	moduleList: [ROLE_MODULES.PRODUCTS],
	// 	menuIcon: 'category',
	// 	linkTo: '/products',
	// },
	// {
	// 	menuText: 'People',
	// 	moduleList: [ROLE_MODULES.PEOPLEX],
	// 	menuIcon: 'people_alt_icon',
	// 	linkTo: '/peoplex',
	// },
	// {
	// 	menuText: 'Models',
	// 	moduleList: [ROLE_MODULES.MODELX],
	// 	menuIcon: 'psychology_icon',
	// 	linkTo: '/modelx',
	// },
	// {
	// 	menuText: 'Boards',
	// 	moduleList: [ROLE_MODULES.GLASS],
	// 	menuIcon: 'view_column',
	// 	linkTo: '/glassx',
	// },
	// {
	// 	menuText: 'My Tasks',
	// 	moduleList: [ROLE_MODULES.GLASS],
	// 	menuIcon: 'library_books_icon',
	// 	linkTo: '/mytasks',
	// },
	{
		menuText: 'Email Templates',
		moduleList: [ROLE_MODULES.EMAIL_NOTIFICATION],
		menuIcon: 'mail',
		linkTo: '/emails',
	},
	{
		menuText: 'App Settings',
		moduleList: [ROLE_MODULES.APP_SETTINGS],
		menuIcon: 'miscellaneous_services',
		linkTo: '/appsettings',
	},
	// {menuText:'Workflow Managment', moduleList:[ROLE_MODULES.], menuIcon:'repeat', linkTo:'/workflows'},
]

const navMenuList = [
	// {
	// 	menuText: 'Dashboard',
	// 	moduleList: [ROLE_MODULES.DASHBOARDS],
	// 	menuIcon: 'dashboard',
	// 	linkTo: '/dashx',
	// },
	// {
	// 	menuText: 'Data',
	// 	moduleList: [ROLE_MODULES.DISCOVERX],
	// 	menuIcon: 'search',
	// 	linkTo: '/discoverx',
	// },
	// {
	// 	menuText: 'Projects',
	// 	moduleList: [ROLE_MODULES.PROJECTS],
	// 	menuIcon: 'folder',
	// 	linkTo: '/projects',
	// },
	// {
	// 	menuText: 'ProductX',
	// 	moduleList: [ROLE_MODULES.PRODUCTS],
	// 	menuIcon: 'category',
	// 	linkTo: '/products',
	// },
	// {
	// 	menuText: 'PeopleX',
	// 	moduleList: [ROLE_MODULES.PEOPLEX],
	// 	menuIcon: 'people_alt_icon',
	// 	linkTo: '/peoplex',
	// },
	// {
	// 	menuText: 'ModelX',
	// 	moduleList: [ROLE_MODULES.MODELX],
	// 	menuIcon: 'psychology_icon',
	// 	linkTo: '/modelx',
	// },
	// {
	// 	menuText: 'GlassX',
	// 	moduleList: [ROLE_MODULES.GLASS],
	// 	menuIcon: 'view_column',
	// 	linkTo: '/glassx',
	// },
	// {
	// 	menuText: 'My Tasks',
	// 	moduleList: [ROLE_MODULES.GLASS],
	// 	menuIcon: 'library_books_icon',
	// 	linkTo: '/mytasks',
	// },
	// {menuText:'Analysis', menuIcon:'analytics', linkTo:'/analysis'},
	// {menuText:'Pipeline', menuIcon:'mediation', params:{
	//     target:'_blank',
	//     onClick:(event) => {event.preventDefault(); window.open("http://pipeline.wersel.io/cdap/ns/default/control");}
	// }, linkTo:'/pipeline'}
]

const getFlowMenus = (workflowUrl, dataflowUrl, isUserHasAdminRole, openIframeDialog) => {
	var flowMenus = []

	if (!isUserHasAdminRole) return flowMenus

	if (!_.isEmpty(dataflowUrl))
		flowMenus.push({
			menuText: 'Dataflow',
			moduleList: '',
			menuIcon: 'account_tree',
			linkTo: () => {
				openIframeDialog('Wersel Dataflow', dataflowUrl)
			},
			openNewTab: true,
		})
	if (!_.isEmpty(workflowUrl))
		flowMenus.push({
			menuText: 'Workflow',
			moduleList: '',
			menuIcon: 'cloud_sync',
			linkTo: () => {
				openIframeDialog('Wersel Workflow', workflowUrl)
			},
			openNewTab: true,
		})
	return flowMenus
}

const getOnlyAllowedMenus = (menuList, userPermissions) => {
	const checkModuleIsAllowed = (moduleName) => isModuleAccessGranted(moduleName, PERMISSION_TYPE.VIEW, userPermissions)
	return _.filter(menuList, (menu) => {
		const mouduleList = _.get(menu, 'moduleList', [])
		return _.isEmpty(mouduleList) ? true : _.some(mouduleList, (moduleName) => checkModuleIsAllowed(moduleName))
	})
}

//styles for accounts popup
const useStylesAccPopup = makeStyles((theme) => ({
	accountPopper: { zIndex: 1000 },
	popper: {
		top: '10px!important',
	},
	paper: {
		boxShadow: '0 1px 2px 0 rgba(60,64,67,0.10), 0 2px 6px 2px rgba(60,64,67,0.15)',
	},
	container: {
		minWidth: '250px',
	},
	profileContent: {
		padding: `${theme.spacing(2)}px ${theme.spacing(2)}px 0`,
		textAlign: 'center',
	},
	listItem: {
		borderTop: `1px solid ${theme.palette.grey[300]}`,
	},
	profileFooter: {
		paddingBottom: theme.spacing(2),
		textAlign: 'center',
	},
	secondayInfo: {
		color: theme.palette.grey[500],
	},
	avatar: {
		margin: '8px auto 12px',
		background: theme.palette.warning.main,
		textTransform: 'uppercase',
		width: '83px',
		height: '83px',
	},
	logoutBtn: {
		textTransform: 'none',
		marginTop: theme.spacing(1),
	},
}))

const isActiveLink = (activeLink, link) => {
	let pathArray = activeLink.split('/')
	return pathArray.length > 1 ? new RegExp(`^/${pathArray[1]}`).test(link) : false
}

const Header = ({
	user,
	logout,
	dismissLogOutError,
	history,
	fetchProfile,
	resetProfile,
	fetchAppConfigs,
	appSettings,
	isLoggingOut,
	logOutError,
	isShowLogOutError,
	location,
	isSocketConnected,
	socketRef,
	openIframeDialog,
}) => {
	const settingAnchorRef = React.useRef(null)
	const accountAchorRef = React.useRef(null)

	const token = useSelector((state) => _.get(state, 'session.authToken', ''))

	const theme = useTheme()
	const isTabDevice = useMediaQuery(theme.breakpoints.down('1350'))
	const isMobileView = useMediaQuery(theme.breakpoints.down('375'))
	const isSmallMobile = useMediaQuery(theme.breakpoints.down('350'))

	const dataflowUrl = useMemo(() => _.get(appSettings, 'data.dataflow_url'), [appSettings])
	const workflowUrl = useMemo(() => _.get(appSettings, 'data.workflow_url'), [appSettings])

	const openNewLinkOnNewTab = (link) => {
		window.open(addHttp(link), '_blank')
	}

	const [state, setState] = useState({
		openSettingsMenus: false,
		isDrawerOpened: false,
		openAccountPopup: false,
		openNotifications: false,
	})

	const { isDrawerOpened, openAccountPopup, openSettingsMenus, openNotifications } = state

	const toggleDrawer = useCallback(() => {
		setState((prevState) => ({ ...prevState, isDrawerOpened: !isDrawerOpened }))
	}, [isDrawerOpened])

	const closeAllPopups = useCallback(() => {
		setState((prevState) => ({
			...prevState,
			isDrawerOpened: false,
			openSettingsMenus: false,
			openAccountPopup: false,
			openNotifications: false,
		}))
	}, [])

	const performLogout = useCallback(() => {
		//CALL LOGOUT FUNCTION
		toggleDrawer()
		logout()
		resetProfile()
	}, [logout, resetProfile, toggleDrawer])

	const performDismissLogoutErr = useCallback(() => {
		if (isShowLogOutError) dismissLogOutError()
	}, [isShowLogOutError, dismissLogOutError])

	const redirectToLink = useCallback(
		(link, isOpenNewTab) => {
			closeAllPopups()
			if (typeof link === 'function') {
				link()
			} else if (isOpenNewTab) {
				openNewLinkOnNewTab(link)
			} else link && history.push(link)
		},
		[history, closeAllPopups]
	)

	const fetchUserDetails = useCallback(() => {
		fetchProfile()
		fetchAppConfigs()
	}, [fetchProfile, fetchAppConfigs])

	useEffect(() => {
		fetchUserDetails()
	}, [fetchUserDetails])

	const activeLink = location.pathname
	const { isFetchingUser, userDetails } = user

	const userDisplayName = _.startCase(_.join([_.get(userDetails, 'first_name', '#'), _.get(userDetails, 'last_name')], ' '))
	const avatarChar = userDisplayName.substring(0, 1)

	const togglePopup = (key, val = null) => {
		val != null ? setState({ ...state, [key]: !state[key] }) : setState({ ...state, [key]: false })
	}

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			event.preventDefault()
			togglePopup('openSettingsMenus')
		}
	}

	const [isLogoOkay, setIsLogoOkay] = useState(true)

	const userProfilePic = _.get(userDetails, 'profile_pic_url') && getImgUrl(token, _.get(userDetails, 'profile_pic_url'), 50)

	const userEmail = _.get(userDetails, 'email', '-')
	const tenentName = useSelector((state) => _.get(state, 'appSettings.data.tenant_name', ''))
	const notificationCount = useSelector((state) => _.get(state, 'notifications.unreadCount', 0))

	const userPermissions = _.get(user, 'userDetails.module_permissions', [])
	const settingsMenusToShow = getOnlyAllowedMenus(settingsMenuList, userPermissions)
	const navMenusToShow = getOnlyAllowedMenus(navMenuList, userPermissions)

	const glassPerms = usePermission(ROLE_MODULES.GLASS)
	const isGlassViewAllowed = useMemo(() => _.get(glassPerms, PERMISSION_TYPE.VIEW, false), [glassPerms])
	const isGlassExist = _.get(userDetails, 'is_glass_exist', false)

	const isUserHasAdminRole = userHasAdminRole(_.get(user, 'userDetails.roles', []))
	const flowMenusToShow = getFlowMenus(workflowUrl, dataflowUrl, isUserHasAdminRole, openIframeDialog)

	return (
		<div>
			<Helmet>
				<title>{_.isEmpty(_.get(appSettings, 'data.company_name')) ? 'Wersel' : _.get(appSettings, 'data.company_name', 'Wersel')}</title>
				{!_.isEmpty(_.get(appSettings, 'data.favicon')) ? (
					<link rel='shortcut icon' sizes='16x16' href={getImgUrl(token, _.get(appSettings, 'data.favicon', ''))} />
				) : (
					<link rel='shortcut icon' sizes='16x16' href={favicon} />
				)}
				{/* // <link rel='shortcut icon' href={!_.isEmpty(_.get(appSettings, 'data.favicon'))?getImgUrl(_.get(appSettings, 'data.favicon','')):'favicon'}></link> */}
			</Helmet>
			<AppBar className='white-bg no-shadow appbar-md' position='fixed'>
				<Toolbar className='toolbar' style={{ paddingRight: isMobileView ? '5px' : '16px', paddingLeft: isMobileView ? '1px' : '16px' }}>
					{/* MOBILE OR TABLET NAVIGATION MENUS */}
					{isTabDevice && (
						<>
							<Drawer
								isOpened={isDrawerOpened}
								menusList={[...navMenusToShow]}
								tenentName={tenentName}
								username={!isFetchingUser && userDisplayName ? userDisplayName : isFetchingUser ? '...' : 'Guest'}
								profilePicture={userProfilePic}
								email={userEmail}
								logoutFunc={performLogout}
								activeLink={activeLink}
								toggleDrawer={toggleDrawer}
								redirectToLink={redirectToLink}
							></Drawer>
							<IconButton
								className='mobile-menu-ico'
								aria-label='Menu'
								onClick={toggleDrawer}
								style={{ padding: isSmallMobile ? '12px 5px' : '12px' }}
							>
								<Icon>menu</Icon>
							</IconButton>
							<span className='hidden-in-mobile'>&nbsp;&nbsp;</span>
						</>
					)}

					{/* APP LOGO */}
					{_.get(appSettings, 'isLoading', true) ? (
						<Skeleton variant='rect' className='appbar-logo' width={160} height={40} />
					) : _.get(appSettings, 'isError', false) ? (
						<img
							src={logo}
							onClick={() => history.push('/home')}
							alt='wersel logo'
							className='appbar-logo'
							style={{ width: isSmallMobile ? '120px' : '150px' }}
						/>
					) : (
						<img
							src={isLogoOkay && !_.isEmpty(_.get(appSettings, 'data.logo', '')) ? getImgUrl(token, _.get(appSettings, 'data.logo', '')) : logo}
							onClick={() => history.push('/home')}
							onError={() => setIsLogoOkay(false)}
							alt={`${_.get(appSettings, 'data.company_name', 'Wersel')} Logo`}
							title={`${_.get(appSettings, 'data.company_name', 'Wersel')} Logo`}
							className='appbar-logo'
							style={{ width: isSmallMobile ? '120px' : '150px' }}
						/>
					)}

					{!isTabDevice && (
						<Grid container spacing={1}>
							{/* DESKTOP NAVIGATION MENUS */}
							<Grid container item lg={12}>
								<NavMenus menusList={navMenusToShow} activeLink={activeLink}></NavMenus>
							</Grid>
						</Grid>
					)}

					<div className='profile-info'>
						{isGlassViewAllowed && isGlassExist && (
							<IconButton
								title='Mytasks'
								onClick={() => {
									redirectToLink('/mytasks')
								}}
								aria-label='Mytasks'
								style={{ padding: isSmallMobile ? '12px 8px' : '12px' }}
							>
								<Icon fontSize='small'>library_books_icon</Icon>
							</IconButton>
						)}
						{/* <Hidden xsDown>
							{isUserHasAdminRole && !_.isEmpty(dataflowUrl) && (
								<IconButton title='Dataflow' onClick={() => openIframeDialog('Wersel Dataflow', dataflowUrl)} aria-label='data flow url'>
									<Icon fontSize='small'>account_tree</Icon>
								</IconButton>
							)}
							{isUserHasAdminRole && !_.isEmpty(workflowUrl) && (
								<IconButton title='Workflow' onClick={() => openIframeDialog('Wersel Workflow', workflowUrl)} aria-label='workflow url'>
									<Icon fontSize='small'>cloud_sync</Icon>
								</IconButton>
							)}
						</Hidden> */}
						<IconButton
							onClick={() => togglePopup('openNotifications', openNotifications)}
							aria-label='settings'
							style={{ padding: isSmallMobile ? '12px 10px' : '12px' }}
						>
							<Badge badgeContent={notificationCount} color='error'>
								<Icon fontSize='small'>notifications</Icon>
							</Badge>
						</IconButton>
						{!_.isEmpty(settingsMenusToShow) && (
							<>
								<IconButton
									onClick={() => togglePopup('openSettingsMenus', openSettingsMenus)}
									ref={settingAnchorRef}
									aria-label='settings'
									style={{ padding: isSmallMobile ? '12px 8px' : '12px' }}
								>
									<Icon fontSize='small'>settings</Icon>
								</IconButton>
								<SettingsMenu
									open={openSettingsMenus}
									anchorEl={settingAnchorRef}
									activeLink={activeLink}
									menuList={settingsMenusToShow}
									redirectToLink={redirectToLink}
									handleClose={() => togglePopup('openSettingsMenus')}
									handleListKeyDown={handleListKeyDown}
								/>
							</>
						)}
						<Hidden xsDown>
							<div className='user-name-info'>
								{!isFetchingUser && userDisplayName ? (
									<>
										<Avatar
											onClick={() => togglePopup('openAccountPopup', openAccountPopup)}
											ref={accountAchorRef}
											size='small'
											className={classNames('profile-circle', { active: openAccountPopup }, 'background-circle')}
											style={{ backgroundColor: stringToHslColor(userDisplayName) }}
											component='div'
											src={userProfilePic}
										>
											<Typography variant='caption'>{avatarChar}</Typography>
										</Avatar>
										<AccountPopup
											avatarChar={avatarChar}
											tenentName={tenentName}
											open={openAccountPopup}
											anchorEl={accountAchorRef}
											redirectToLink={redirectToLink}
											handleClose={() => togglePopup('openAccountPopup')}
											userDetails={userDetails}
											userProfilePic={userProfilePic}
											logoutFunc={performLogout}
										/>
									</>
								) : isLoggingOut ? (
									<CircularProgress variant='indeterminate' size={16} thickness={2} />
								) : (
									<Skeleton variant='circle' width={25} height={25} />
								)}
							</div>
						</Hidden>
					</div>
				</Toolbar>

				<Snackbar
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
					open={isShowLogOutError}
					onClose={performDismissLogoutErr}
					autoHideDuration={1000}
					ContentProps={{
						'aria-describedby': 'message-id',
					}}
					message={<span id='message-id'>{logOutError && logOutError.message}</span>}
				/>

				<NotificationDrawer
					isSocketConnected={isSocketConnected}
					socketRef={socketRef}
					open={openNotifications}
					handleClose={() => togglePopup('openNotifications')}
				/>
			</AppBar>
		</div>
	)
}

const AccountPopup = ({ open, anchorEl, handleClose, redirectToLink, userDetails, avatarChar, userProfilePic, logoutFunc, tenentName }) => {
	const classes = useStylesAccPopup()
	const username = _.startCase(_.join([_.get(userDetails, 'first_name', '#'), _.get(userDetails, 'last_name')], ' '))
	const email = _.get(userDetails, 'email')

	const onClickMyAccSettings = () => {
		redirectToLink('/myaccount')
	}

	return (
		<Popper
			className={classes.popper}
			placement='bottom-end'
			open={open}
			anchorEl={anchorEl.current}
			role={undefined}
			transition
			disablePortal
			// className={classes.accountPopper}
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === 'bottom' ? 'right bottom' : 'right top',
					}}
				>
					<Paper className={classes.paper}>
						<ClickAwayListener onClickAway={handleClose}>
							<div className={classes.container}>
								<div className={classes.profileContent}>
									<Avatar
										size='large'
										className={classes.avatar}
										src={userProfilePic}
										alt={username}
										style={{ backgroundColor: stringToHslColor(username) }}
									>
										<Typography variant='h3'>{avatarChar}</Typography>
									</Avatar>
									<Typography variant='body1' component={'div'}>
										{username}
									</Typography>
									<Typography className={classes.secondayInfo} variant='body2' component={'div'} gutterBottom>
										{email}
									</Typography>
									{!_.isEmpty(tenentName) && (
										<Typography className={classes.secondayInfo} variant='body2' component={'div'} gutterBottom>
											{`Tenant : ${tenentName}`}
										</Typography>
									)}
								</div>

								<List>
									<ListItem onClick={onClickMyAccSettings} className={classes.listItem} divider button>
										<ListItemIcon>
											<Icon fontSize='small'>settings</Icon>
										</ListItemIcon>
										<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary='Account Settings' />
										<ListItemSecondaryAction>
											<Icon fontSize='small'>navigate_next</Icon>
										</ListItemSecondaryAction>
									</ListItem>
								</List>
								<div className={classes.profileFooter}>
									<Button onClick={logoutFunc} aria-label='logout' className={classes.logoutBtn} size='small' variant='outlined' color='primary'>
										Sign out
									</Button>
								</div>
							</div>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	)
}

const SettingsMenu = ({ open, anchorEl, activeLink, handleListKeyDown, redirectToLink, menuList, handleClose }) => {
	const classes = useStylesSettingsMenu()
	return (
		<Popper
			placement='bottom-end'
			open={open}
			anchorEl={anchorEl.current}
			role={undefined}
			transition
			disablePortal
			className={classes.settingsPopper}
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === 'bottom' ? 'right bottom' : 'right top',
					}}
				>
					<Paper className={classes.paper}>
						<ClickAwayListener onClickAway={handleClose}>
							<MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown}>
								{menuList.map(({ menuText, menuIcon, linkTo }) => (
									<MenuItem
										key={linkTo}
										dense={true}
										className={classNames(classes.settingsMenuItem, {
											active: isActiveLink(activeLink, linkTo),
										})}
										onClick={() => {
											redirectToLink(linkTo)
										}}
									>
										<ListItemIcon classes={{ root: classes.listItemIcoRoot }}>
											<Icon
												className={classNames(classes.menuIcon, {
													active: isActiveLink(activeLink, linkTo),
												})}
												fontSize='small'
											>
												{menuIcon}
											</Icon>
										</ListItemIcon>
										<Typography className={classes.menuText} variant='inherit'>
											{menuText}
										</Typography>
									</MenuItem>
								))}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	)
}

const NavMenus = (props) => {
	return (
		<Grid className='menu-container nav-grid' container direction='row' justifyContent='flex-start' alignItems='center'>
			{props.menusList.map((menu) => {
				return (
					<Link
						to={menu.linkTo}
						key={`desktop-menu-${menu.linkTo}`}
						className={classNames('nav-item', {
							active: isActiveLink(props.activeLink, menu.linkTo),
						})}
						{..._.get(menu, 'params', {})}
					>
						<Icon className='nav-ico'>{menu.menuIcon}</Icon>
						<span>{menu.menuText}</span>
					</Link>
				)
			})}
		</Grid>
	)
}

Header.propTypes = {
	isLoggingOut: PropTypes.bool,
	logOutError: PropTypes.object,
	isShowLogOutError: PropTypes.bool,
	isSocketConnected: PropTypes.bool,
	socketRef: PropTypes.any,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
