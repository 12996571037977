import { makeStyles } from '@material-ui/core'
import InputLabels from 'components/InputLabels'
import Attachments from 'components/Attachments'
import React from 'react'
import MoreInfo from './MoreInfo'
import StickyDetailView from './StickyDetailView'
import ResultsList from './ResultList'
import Activities from 'components/Activities'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ProductList from './ProductList'

const useStyles = makeStyles((theme) => ({
	varticalSpace: {
		padding: theme.spacing(0, '20px', 3, '20px'),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 2),
		},
	},
	mt3: {
		padding: theme.spacing(3, 0, 0, 0),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2, 2, 0, 2),
		},
	},
	bold: {
		fontWeight: '500',
	},
}))

const TaskInfo = ({
	glassMembers,
	glassCode,
	glassLabels,
	sessionId,
	formik,
	stickyDetails,
	isEditEnabled,
	isGuest,
	attachmentEditEnabled,
	panes,
	loading,
	stickyId,
	onAddAttachments,
	attachmentLoading,
	onDeleteAttachment,
	socketRef,
	isSocketConnected,
	membersSuggestions,
	isOwnerOrReporter,
	isMember,
	history,
	handleCustomLabel,
	sticky_group,
}) => {
	const classes = useStyles()
	console.log('ATTACHMENT', formik?.values?.attachments)
	return (
		<div>
			<StickyDetailView
				glassMembers={glassMembers}
				membersSuggestions={membersSuggestions}
				formik={formik}
				disabled={!isEditEnabled}
				estimationEnabled={attachmentEditEnabled}
				glassCode={glassCode}
				stickyCode={stickyId}
				stickyDetails={stickyDetails}
				panes={panes}
				sticky_group={sticky_group}
			/>
			<div className={classes.varticalSpace}>
				<InputLabels
					disabled={!isEditEnabled}
					name='labels'
					hideAddLabel={isMember}
					hideEditLabel={isMember}
					onChange={formik.handleChange}
					value={formik?.values?.labels}
					labels={glassLabels}
					glassCode={glassCode}
					sessionId={sessionId}
					isDescription={true}
					handleCustomLabel={handleCustomLabel}
					newCustomLabel={true}
				/>
			</div>
			{_.isEqual(stickyDetails?.sticky_for, 'discoverx') && (
				<div className={classes.spacing}>
					<ResultsList glassId={glassCode} cardId={stickyId} history={history} />
				</div>
			)}
			{_.isEqual(stickyDetails?.sticky_for, 'rudder') && (
				<div className={classes.spacing}>
					<ResultsList glassId={glassCode} cardId={stickyId} history={history} />
				</div>
			)}
			{_.isEqual(stickyDetails?.sticky_for, 'products') && (
				<div className={classes.spacing}>
					<ProductList glassId={glassCode} cardId={stickyId} history={history} />
				</div>
			)}
			<div className={classes.varticalSpace}>
				<Attachments
					name='attachments'
					attachmentList={formik?.values?.attachments}
					enableAddBtn={attachmentEditEnabled}
					disableDelete={!attachmentEditEnabled}
					showDivider={false}
					onAddAttachments={onAddAttachments}
					loading={attachmentLoading}
					onDeleteAttachment={onDeleteAttachment}
					enableUrlBtn={true}
				/>
			</div>
			<div className={classes.varticalSpace}>
				<MoreInfo stickyDetails={stickyDetails} loading={loading} />
			</div>
			<div className={classes.varticalSpace}>
				<Activities
					title={'Pinned Activities'}
					isEditEnabled={isOwnerOrReporter}
					sessionId={sessionId}
					is_pinned={true}
					glassCode={glassCode}
					stickyId={stickyId}
					socketRef={socketRef}
					isSocketConnected={isSocketConnected}
				/>
			</div>
		</div>
	)
}

TaskInfo.propTypes = {
	glassCode: PropTypes.string,
	glassMembers: PropTypes.array,
	glassLabels: PropTypes.array,
	sessionId: PropTypes.string,
	formik: PropTypes.object,
	stickyDetails: PropTypes.object,
	isEditEnabled: PropTypes.bool,
	attachmentEditEnabled: PropTypes.bool,
	stickyId: PropTypes.string,
	loading: PropTypes.bool,
	onAddAttachments: PropTypes.func,
	attachmentLoading: PropTypes.bool,
	onDeleteAttachment: PropTypes.func,
	socketRef: PropTypes.object,
	isSocketConnected: PropTypes.bool,
	membersSuggestions: PropTypes.array,
}

export default TaskInfo
