

import { combineReducers } from 'redux'
import session from '../session/reducers'
import snackbar from '../snackbar/reducers'
import snackbarAdvance from '../snackbarAdvance/reducers'
import loader from '../loader/reducers'
import appSettings from '../appsettings/reducers'
import notifications from '../notifications/reducers'

export default combineReducers({
	session,
	snackbar,
	snackbarAdvance,
	loader,

	appSettings,

	notifications,
})
