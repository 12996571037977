import { API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
const CancelToken = Axios.CancelToken

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	createTemplate: (data, successCallback, errorCallback) => {
		Axios.post(`${API_ENDPOINTS.CREATE_TEMPLATE}`, { ...data }, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchAllTemplates: (search, offset, limit, searchFields, cancelExecutor, successCallback, errorCallback) => {
		if (cancelExecutor.current) cancelExecutor.current()
		Axios.get(`${API_ENDPOINTS.GET_TEMPLATES}`, {
			cancelToken: new CancelToken(function executor(c) {
				cancelExecutor.current = c
			}),
			params:{ search_text:search, offset, limit, search_fields:searchFields },
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	fetchSingleTemplate: (templateCode, successCallback, errorCallback) => {
		Axios.get(`${API_ENDPOINTS.SINGLE_TEMPLATE.replace(':templateCode', templateCode)}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	editTemplate: (data, templateCode, successCallback, errorCallback) => {
		Axios.put(
			`${API_ENDPOINTS.SINGLE_TEMPLATE}`,
			{ ...data },
			{
				headers: {
					'Content-Type': 'application/json',
					isAuthRequired: true,
					path: { templateCode },
				},
			}
		)
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	deleteTemplate: (templateCode, successCallback, errorCallback) => {
		Axios.delete(`${API_ENDPOINTS.SINGLE_TEMPLATE}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
				path: { templateCode },
			},
		})
			.then((resp) => {
				successCallback && successCallback(resp)
			})
			.catch((err) => {
				errorCallback && errorCallback(err)
			})
	},
	createCategory: (data) => {
		return Axios.post(`${API_ENDPOINTS.CREATE_CATEGORY}`, data, { headers: { 'Content-Type': 'application/json', isAuthRequired: true } })
	},
	fetchCategories: () => {
		return Axios.get(`${API_ENDPOINTS.CREATE_CATEGORY}`, {
			headers: {
				'Content-Type': 'application/json',
				isAuthRequired: true,
			},
		})
	},
}
