import { ROLE_MODULES } from 'constants/modules'
import { NOTIFICATIONS } from 'constants/notifications'

const getModuleNotifications = (module) => {
	return NOTIFICATIONS[module]
}

const doGlassXNotificationActions = (notification, history) => {
	const GLASSX_NOTIFICATIONS = getModuleNotifications(ROLE_MODULES.GLASS)
	const action = notification?.action
	switch (true) {
		case [
			GLASSX_NOTIFICATIONS.CREATE_BOARD,
			GLASSX_NOTIFICATIONS.ADD_MEMEBER,
			GLASSX_NOTIFICATIONS.UPDATE_GLASSX_NAME,
			GLASSX_NOTIFICATIONS.DELETE_STICKY,
		].indexOf(action) > -1:
			history(`/glassx/view/${notification?.resource_id}`)
			break
		case action === GLASSX_NOTIFICATIONS.CHANGE_ROLE:
			history(`/glassx/view/${notification?.resource_id}?settings=true&tabId=members`)
			break
		case action === GLASSX_NOTIFICATIONS.DELETE_BOARD:
			// No action
			break
		case action === GLASSX_NOTIFICATIONS.REMOVE_MEMEBER:
			// No action
			break
		case [
			GLASSX_NOTIFICATIONS.CREATE_STICKY,
			GLASSX_NOTIFICATIONS.UPDATE_STICKY_OKR,
			GLASSX_NOTIFICATIONS.STICKY_DUE_DATE_AND_TIME,
			GLASSX_NOTIFICATIONS.STICKY_ASSIGNEE_CHANGE,
			GLASSX_NOTIFICATIONS.STICKY_REPORTING_TO_CHANGE,
			GLASSX_NOTIFICATIONS.SITKCY_RENAME,
			GLASSX_NOTIFICATIONS.SITKCY_CREATE_SUBTASK,
			GLASSX_NOTIFICATIONS.STICKY_SET_ASSIGNEE,
			GLASSX_NOTIFICATIONS.STICKY_REMOVE_ASSIGNEE,
			GLASSX_NOTIFICATIONS.MOVE_STICKY,
			GLASSX_NOTIFICATIONS.STICKY_ADD_REPORTING_TO,
			GLASSX_NOTIFICATIONS.STICKY_REMOVE_REPORTING_TO,
		].indexOf(action) > -1:
			history(`/glassx/view/${notification?.resource_id}/${notification?.sub_resource_id}`)
			break
		case [GLASSX_NOTIFICATIONS.STICKY_POST_ACTIVITY, GLASSX_NOTIFICATIONS.STICKY_TAGGED_ACTIVITY, GLASSX_NOTIFICATIONS.STICKY_STATUS].indexOf(
			action
		) > -1:
			history(`/glassx/view/${notification?.resource_id}/${notification?.sub_resource_id}?tabId=update`)
			break

		default:
			break
	}
}

export default function notificationActionPerformer(notification, history) {
	switch (notification?.module) {
		case ROLE_MODULES.GLASS:
			doGlassXNotificationActions(notification, history)
			break
		default:
			break
	}
}
