import React, { useState, useEffect, useRef } from 'react'
import { BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom'
import { Fab, Grow, Icon, makeStyles, ClickAwayListener } from '@material-ui/core'
import Header from './components/Header'
import PrivateRoute from './components/PrivateRoute'
import { connect, useDispatch } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import Loader from './components/Loader'
import { ROLE_MODULES } from './constants/modules'
import { Alert } from '@material-ui/lab'
import { hideAdvaceSnackbar } from './services/snackbarAdvance/actions'
// import { io } from 'socket.io-client'
import { API_ENDPOINTS } from './constants/index'
// import notificationsWatcher from './notificationsWatcher'
import { addHttp } from './utils'
import IframeDialog from './components/IframeDialog'
import AppSettings from 'scences/AppSettings'
import PageNotFound from './scences/404'
import PageNotAllowed from './scences/403'
import Login from './scences/Login'
import Home from './scences/Home'
import SetNewPassword from 'scences/SetNewPassword'
import MyAccount from 'scences/MyAccount'
import Emails from 'scences/Emails'
import MyTasks from 'scences/MyTasks'


const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'fixed',
		zIndex: 100,
		bottom: '7px',
		right: '24px',
		[theme.breakpoints.down('sm')]: {
			display: 'inline-flex',
		},
	},
	chatbot: {
		display: 'flex',
		flexWrap: 'wrap',
		position: 'absolute',
		bottom: '55px',
		right: '100px',
	},
}))

export function App(props) {
	const {
		snackbar: { showSnackbar, msgText },
		isAuthenticated,
		loader,
		authToken,
		userId,
		advanceSnackbar: { showAdvanceSnackbar, severity, snackbarText, onClose },
	} = props
	const dispatch = useDispatch()
	const usrMgnModuleMapper = {
		users: ROLE_MODULES.USER_MANAGEMENT,
		roles: ROLE_MODULES.ROLE_MANAGEMENT,
		teamunits: ROLE_MODULES.TEAM_MANAGEMENT,
	}

	const socketIO = useRef()
	const [{ isSocketConnected }, setSocketState] = useState({ isSocketConnected: false, sessionId: '' })

	const classes = useStyles()

	const [openChat, setOpenChat] = useState(false)


	const [iFrameState, setIframeState] = useState({ open: false, title: '', url: '' })

	const openIframeDialog = (title, url) => {
		setIframeState({ open: true, title, url: addHttp(url) })
	}

	const closeIframeDialog = () => {
		setIframeState({ open: false, title: '', url: '' })
	}

	return (
		<>
		<Router>
				<div className='App'>
					{isAuthenticated && <Header socketRef={socketIO} isSocketConnected={isSocketConnected} openIframeDialog={openIframeDialog}></Header>}
					<Switch>
						<Route exact path='/' component={Login} />
						<Route exact path='/setnewpassword' component={SetNewPassword} />
						<PrivateRoute exact path='/home' component={Home} />
						<PrivateRoute exact path='/myaccount' component={MyAccount} />
						<PrivateRoute
							exact
							path='/appsettings/:module?'
							moduleName={ROLE_MODULES.APP_SETTINGS}
							component={AppSettings}
							injectComponentProps={{ openIframeDialog }}
						/>
						<Route path='/403' component={PageNotAllowed} />
						<PrivateRoute exact path='/emails' moduleMapper={ROLE_MODULES.EMAIL_NOTIFICATION} component={Emails} />
						<PrivateRoute exact path='/emails/:action/:id?' moduleMapper={ROLE_MODULES.EMAIL_NOTIFICATION} component={Emails} />
						<Route exact path='/mytasks' moduleName={ROLE_MODULES.GLASS} component={MyTasks} />
						<Route exact path='/mytasks/:action/:glassCode/:cardId' moduleName={ROLE_MODULES.GLASS} component={MyTasks} />
						<Route path='*' component={PageNotFound} />
					</Switch>
				
				</div>
				</Router>
			<Snackbar open={showSnackbar} TransitionComponent={Slide} message={msgText} />

			<IframeDialog open={iFrameState.open} title={iFrameState.title} url={iFrameState.url} handleClose={closeIframeDialog} />

			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={showAdvanceSnackbar}
				onClose={onClose ? () => dispatch(hideAdvaceSnackbar()) : null}
				autoHideDuration={onClose ? 2000 : null}
			>
				<Alert severity={severity} onClose={onClose ? () => dispatch(hideAdvaceSnackbar()) : null}>
					{snackbarText}
				</Alert>
			</Snackbar>

			<Loader {...loader}></Loader>
		</>
	)
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.session.isAuthenticated,
	authToken: state.session?.authToken,
	userId: state.session?.user?.userDetails?.user_id,
	snackbar: state.snackbar,
	advanceSnackbar: state.snackbarAdvance,
	loader: state.loader,
})

export default connect(mapStateToProps)(App)
