
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Form from './Form'
import { login } from '../../services/session/actions'
import { connect } from 'react-redux'
import ForgotPasswordPopup from './ForgotpasswordPopup'
// import { ROLE_MODULES } from 'constants/modules'

export class Login extends Component {
	constructor() {
		super()
		this.state = {
			openForgotPopup: false,
			tenentName: '',
		}
	}
	toggleForgotClick = () => {
		this.setState({ openForgotPopup: !this.state.openForgotPopup })
	}
	onForgotClick = () => {
		this.toggleForgotClick()
	}

	render() {
		return (
			<div>
				<Form onClickForgotLink={this.onForgotClick} tenantName={this.state.tenentName} {...this.props}></Form>
				<ForgotPasswordPopup open={this.state.openForgotPopup} tenantName={this.state.tenentName} handleClose={this.toggleForgotClick} />
			</div>
		)
	}

	componentDidMount() {
		this.redirectToHomeIfLoggedIn()
		// Function to check if a string is a valid IP address (IPv4)
		function isValidIP(ip) {
			const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){2}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
			return regex.test(ip);
		}
		
		var hostname = window.location.hostname;
		
		// Check if hostname is an IP address
		if (isValidIP(hostname)) {
			// If it is an IP address, set tenantName to an empty string
			this.setState({
			tenantName: '',
			});
		} else {
			// If it's a valid domain name, extract the tenant name
			var regexParse = new RegExp('[a-z0-9-]{2,63}.[a-z.]{2,5}$');
			var urlParts = regexParse.exec(hostname);
			if (urlParts) {
			let out = hostname.replace(urlParts[0], '').slice(0, -1);
			this.setState({
				tenantName: out,
			});
			}
		}
		
	}

	//IF LOGIN SUCCESS (OR) ALREADY LOGGED IN THEN RE-DIRECT TO HOME PAGE
	redirectToHomeIfLoggedIn() {
		if (this.props.session.isAuthenticated) {
			// const configLanding = this.props.session.user?.userDetails?.config_landing
			// switch (configLanding) {
			// 	case ROLE_MODULES.DISCOVERX:
			// 		this.props.history.push('/discoverx')
			// 		return
			// default:
			this.props.history.push('/home?loggedIn=true')
			// 	return
		}
	}

	componentDidUpdate() {
		this.redirectToHomeIfLoggedIn()
	}
}

const mapStateToProps = (state) => ({
	session: state.session,
})

const mapDispatchToProps = (dispatch) => ({
	login: (username, tenant, password) => dispatch(login(username, tenant, password)),
})

Login.propTypes = {
	session: PropTypes.object.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
