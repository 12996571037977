import { put, call, all, takeLatest } from 'redux-saga/effects'
import { ACTION_TYPES, SAGA_ACTIONS, API_ENDPOINTS } from '../../constants'
import Axios from 'axios'
import { watchFetchAppConfigs } from '../appsettings/sagas'
import SessionSagas from '../session/sagas'



// function* workGetModelxTable(action) {
// 	yield put({ type: ACTION_TYPES.GET_MODELX_TABLE_PENDING })

// 	try {
// 		const products = yield call(modelxApi.getModelxTableData, action.query, action.search, action.redirect, action.cancelExecutor)
// 		yield put({
// 			type: ACTION_TYPES.GET_MODELX_TABLE_FULFILLED,
// 			payload: products,
// 		})
// 	} catch (err) {
// 		yield put({ type: ACTION_TYPES.GET_MODELX_TABLE_REJECTED, payload: err })
// 		console.log(err)
// 	}
// }

// function* workGetModelxDetails(action) {
// 	yield put({ type: ACTION_TYPES.GET_MODELX_DETAILS_PENDING })

// 	try {
// 		const modelx = yield call(modelxApi.getModelxDataDetails, action.modelxId, action.cancelExecutor)
// 		yield put({
// 			type: ACTION_TYPES.GET_MODELX_DETAILS_FULFILLED,
// 			payload: modelx,
// 		})
// 	} catch (err) {
// 		yield put({ type: ACTION_TYPES.GET_MODELX_DETAILS_REJECTED, payload: err })
// 		console.log(err)
// 	}
// }

// function* workGetModelxLogs(action) {
// 	yield put({ type: ACTION_TYPES.GET_MODELX_LOGS_PENDING })

// 	try {
// 		const modelx_logs = yield call(modelxApi.getModelxLogs, action.modelxId, action.cancelExecutor)
// 		yield put({
// 			type: ACTION_TYPES.GET_MODELX_LOGS_FULFILLED,
// 			payload: modelx_logs,
// 		})
// 	} catch (err) {
// 		yield put({ type: ACTION_TYPES.GET_MODELX_LOGS_REJECTED, payload: err })
// 		console.log(err)
// 	}
// }

// function* watchGetModelxTable() {
// 	yield takeLatest(SAGA_ACTIONS.GET_MODELX_TABLE, workGetModelxTable)
// }
// function* watchGetModelxDetails() {
// 	yield takeLatest(SAGA_ACTIONS.GET_MODELX_DETAILS, workGetModelxDetails)
// }
// function* watchGetModelxLogs() {
// 	yield takeLatest(SAGA_ACTIONS.GET_MODELX_LOGS, workGetModelxLogs)
// }

export default function* rootSaga() {
	yield all([
		// watchGetModelxTable(),
		// watchGetModelxDetails(),
		// watchGetModelxLogs(),
		watchFetchAppConfigs(),
		...SessionSagas,
	])
}
