
import { ACTION_TYPES, SAGA_ACTIONS } from '../../../constants'
import api from '../api'

export const login = (username, tenant, password) => ({
	type: ACTION_TYPES.AUTH_LOGIN,
	payload: api.login(username, tenant, password),
})

export const logout = () => ({
	type: ACTION_TYPES.AUTH_LOGOUT,
	payload: api.logout(),
})

export const dismissLogOutError = () => ({
	type: ACTION_TYPES.AUTH_LOGOUT_DISMISS_ERROR,
})

export const unauthError = () => ({
	type: ACTION_TYPES.UNAUTH_ERROR,
})

export const fetchProfile = () => ({
	type: SAGA_ACTIONS.FETCH_PROFILE,
})

export const resetProfile = () => ({
	type: ACTION_TYPES.RESET_CURRENT_USER,
})

export const changeCurrentUsrPwd = (formData, successCallback, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.UPDATE_USER_PASSWORD,
	payload: { formData, successCallback, dialogCloseBtnCallback },
})

export const setUserDashboard = (dashboardId, config_landing) => ({
	type: ACTION_TYPES.UPDATE_USER_HOME_DASHBOARD,
	payload: { dashboardId, config_landing },
})

export const updateUserDashboard = (dashboardId, config_landing, dialogCloseBtnCallback) => ({
	type: SAGA_ACTIONS.SET_USER_HOMEPAGE_DASHBOARD,
	dashboardId,
	config_landing,
	dialogCloseBtnCallback,
})
