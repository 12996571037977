import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
// Material components
import { Typography, makeStyles, Box, Button, Grid} from '@material-ui/core'
import _ from 'lodash'
// import 'swiper/swiper.scss'
import { DashboardImage } from 'illustrators'
import classnames from 'classnames'
// import DashboardViewer from 'scences/Dashboard/DashboardViewer'
import { usePermission } from 'hooks/usePermission'
import { isModuleHasPerm } from 'utils'
import { PERMISSION_TYPE, ROLE_MODULES } from 'constants/modules'
import { useUrlSearchParams } from 'hooks/use-url-search-params'
import dataconnectionImg from '../../images/dataConnectionImage.png'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		// color: props.labelsColor
	},
	maxWidthWrapper: {
		maxWidth: '1200px',
		width: '100%',
		margin: '0 auto',
		boxSizing: 'border-box',
	},
	userInfo: {
		letterSpacing: '1px',
	},
	userInfoWrapper: {
		paddingTop: theme.spacing(4),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingBottom: theme.spacing(1),
	},
	pb3: {
		paddingBottom: theme.spacing(3),
	},
	dashboardImage: {
		width: '180px',
		height: '125px',
	},
	homeInfoWrapper: {
		display: 'flex',
	},
	messageWrapper: {
		flex: 1,
		paddingTop: theme.spacing(0),
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
		paddingBottom: theme.spacing(1),
	},
	messageTitle: {
		fontSize: '18px',
		lineHeight: '26px',
		letterSpacing: '1px',
		paddingBottom: theme.spacing(0.5),
	},
	messageSubtitle: {
		paddingBottom: theme.spacing(0.5),
	},
	filterPanelClass: {
		paddingTop: '64px',
	},
}))

const Home = ({ history }) => {
	const classes = useStyles()

	const dashboardPerms = usePermission(ROLE_MODULES.DASHBOARDS)
	const isAbleToViewDash = isModuleHasPerm(dashboardPerms, PERMISSION_TYPE.VIEW)
	const [{ loggedIn }] = useUrlSearchParams({}, { loggedIn: Boolean })

	const user = useSelector((state) => _.get(state, 'session.user'))
	const dashboardFilters = useSelector((state) => _.get(state, 'dashboard.inPageFilters', []))

	let { isFetchingUser, isFetchingUserFailed, userDetails } = user
	const onClickConfigBtn = () => {
		history.push('/myaccount?settings=configurehomepage')
	}

	const onClickDataConnectionBtn = () => {
		history.push('/dataconnection/create')
	}

	useEffect(() => {
		if (loggedIn) {
			const configLanding = user?.userDetails?.config_landing
			switch (configLanding) {
				case ROLE_MODULES.DISCOVERX:
					history.push('/discoverx')
					return
				case ROLE_MODULES.MY_TASK:
					history.push('/mytasks')
					return
				default:
					return
			}
		}
	}, [loggedIn, user])

	const dashboardId = _.get(userDetails, 'home_dashboard_id')
	const isApiSuccess = !isFetchingUser && !isFetchingUserFailed
	const userDisplayName = _.startCase(_.join([_.get(userDetails, 'first_name', '#'), _.get(userDetails, 'last_name', '')], ' '))

	const dataConPerms = usePermission(ROLE_MODULES.DATACONNECTION)
	const isCreateAllowed = useMemo(() => _.get(dataConPerms, PERMISSION_TYPE.CREATE, false), [dataConPerms])

	return (
		<div className='page-content'>
			<div className={classes.root}>
				<Box component='div' className={classnames(classes.maxWidthWrapper, classes.userInfoWrapper, { [classes.pb3]: !dashboardId })} lg={12}>
					<Typography variant='h5' className={classes.userInfo}>
						{!_.isEmpty(userDetails)
							? `Hi ${_.startCase(userDisplayName)}, Welcome back`
							: isFetchingUserFailed
							? "I can't find you :("
							: 'Finding you...'}
					</Typography>
					<Typography variant='subtitle1' color='textSecondary' gutterBottom>
						Let's find the right data at the right time.
					</Typography>
				</Box>
				{/* {isApiSuccess &&
					isAbleToViewDash &&
					(_.isEmpty(dashboardId) ? (
						<>
						<Grid container className={classnames(classes.maxWidthWrapper, classes.homeInfoWrapper)}>
						<Grid item xs={12} md={6} lg={6}>
						<Box component='div' className={classnames(classes.maxWidthWrapper, classes.homeInfoWrapper)}>
							<div>
								<DashboardImage className={classes.dashboardImage}></DashboardImage>
							</div>
							<div className={classes.messageWrapper}>
								<Typography className={classes.messageTitle} variant='subtitle1'>
									Looks like you haven't configured, <br /> Your homepage Dashboard!
								</Typography>
								<Typography component='div' className={classes.messageSubtitle} color='textSecondary' variant='caption' gutterBottom>
									You can customize your homepage with a Dashboard.
								</Typography>
								<Button disableElevation onClick={onClickConfigBtn} variant='contained' size='small' color='primary'>
									Configure
								</Button>
							</div>
						</Box>
						</Grid>
						{ isCreateAllowed &&
						<Grid item xs={12} md={6} lg={6}>
						<Box component='div' className={classnames(classes.maxWidthWrapper, classes.homeInfoWrapper)}>
							<div>
							    <img className={classes.dashboardImage} alt={''} src={dataconnectionImg} />
							</div>
							<div className={classes.messageWrapper}>
								<Typography className={classes.messageTitle} variant='subtitle1'>
								    Establish  a Data connection for <br /> enhancing your data!
								</Typography>
								<Typography component='div' className={classes.messageSubtitle} color='textSecondary' variant='caption' gutterBottom>
								    You can create a data connection from different sources.
								</Typography>
								<Button disableElevation onClick={onClickDataConnectionBtn} variant='contained' size='small' color='primary'>
									Add Data Connection
								</Button>
							</div>
						</Box>
						</Grid>
						}
						</Grid>
						</>
					) : (
						<Box component='div' className={classes.dashboardWrapper} lg={12}>
							<DashboardViewer
								forceRefreshNo={0}
								isEditMode={false}
								isEmbedded={true}
								dashboardId={dashboardId}
								filterPanelClass={classes.filterPanelClass}
								inPageFilters={dashboardFilters}
							/>
						</Box>
					))} */}
			</div>
		</div>
	)
}

export default Home
