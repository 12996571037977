
import React, { Component } from 'react'
import InputField from '../../../components/TextField'
import Button from '@material-ui/core/Button'
import { Form } from 'formik'
import Field from 'components/FormikFieldWrapper'
import Validator from './validator'
import CircularProgress from '@material-ui/core/CircularProgress'
import logo from '../../../images/logo.svg'
import loginBG from '../../../images/loginBG.svg'
import loginBGText from '../../../images/loginBGText.svg'
import { Link } from '@material-ui/core'

class LoginForm extends Component {
	constructor() {
		super()
		this.state = {}
	}

	performLogin = (values, { setSubmitting }) => {
		this.props.login(
			values.username,
			values.tenant_name ? values.tenant_name : this.props.tenantName && this.props.tenantName === 'app' ? 'datinfi' : this.props.tenantName,
			values.password
		)
	}
	onForgotClick = () => {
		this.props.onClickForgotLink()
	}

	render() {
		const { isLoading, error } = this.props.session
		return (
			<div className='page-container'>
				<div className='image-container'>
					<img src={loginBG} alt={loginBG} className='loginBG' />
					<img src={loginBGText} alt={loginBGText} className='loginBGText' />
				</div>
				<div className='form-container'>
					<img src={logo} className={'logo'} alt='wersel logo' />

					<Validator onSubmit={this.performLogin}>
						{({ isSubmitting }) => (
							<Form id='loginForm' className='login-form'>
								<Field
									type='text'
									name='username'
									render={({ field, form }) => {
										return <InputField size='small' type='text' label='Email Address' autoFocus={false} field={field} form={form} />
									}}
								/>
								{this.props.tenantName === '' && (
									<Field
										type='text'
										name='tenant_name'
										render={({ field, form }) => {
											return <InputField size='small' type='text' label='Tenant' field={field} form={form} />
										}}
									/>
								)}
								<Field
									type='password'
									name='password'
									render={({ field, form }) => {
										return <InputField size='small' type='password' label='Password' field={field} form={form} />
									}}
								/>

								{error && <div className='error-div'>{error.message}</div>}

								<Button type='submit' className='btn-login' variant='contained' color='secondary' disableElevation disabled={isLoading}>
									{isLoading ? <CircularProgress size={26} className='progressbar' /> : 'Login'}
								</Button>
								<Link className='forgot-btn' onClick={this.onForgotClick}>
									Forgot Password? Click here to change
								</Link>
							</Form>
						)}
					</Validator>
				</div>
			</div>
		)
	}
}

export default LoginForm
