import { createTheme, responsiveFontSizes } from '@material-ui/core/styles'

const lightTheme = createTheme({
	palette: {
		primary: {
			light: '#83c098', //'#62a8d7',
			main: '#65b17f', //'#3b93cd',
			dark: '#467b58', //'#29668f',
			primaryBg: '#e6f7f6',
			contrastText: '#fff',
		},
		// primary: {
		//     light: '#3381ff',
		//     main: '#0062ff',
		//     dark: '#0044b2'
		// },
		secondary: {
			light: '#63ddac',
			main: '#3dd598',
			dark: '#2a956a',
		},
		success: {
			light: '#9bcf63',
			main: '#82c43c',
			dark: '#5b892a',
		},
		error: {
			light: '#fc7b7b',
			main: '#fc5a5a',
			dark: '#b03e3e',
		},
		warning: {
			light: '#ffab6e',
			main: '#ff974a',
			dark: '#b26933',
		},
		info: {
			light: '#73c3ff',
			main: '#50b5ff',
			dark: '#387eb2',
		},
		almostBlack: {
			0: '#ffffff',
			100: '#fafafb',
			200: '#f1f1f5',
			300: '#e2e2ea',
			400: '#d5d5dc',
			500: '#b5b5be',
			600: '#92929d',
			700: '#696974',
			800: '#44444f',
			900: '#171725',
		},
		background: {
			paper: '#fff',
			default: '#fafafb',
		},
		text: {
			primary: '#171725',
			secondary: '#92929d',
			disabled: '#e2e2ea',
			hint: '#e2e2ea',
		},
		others: {
			tableHeaderBg: {
				main: '#9e9c9c14',
			},
		},
		glassX: {
			bgColor: '#fafcff',
			laneColor: '#f2f6fa',
		},
		progress: {
			inprogress: '#ffa500',
			completed: '#65b17f',
		},
	},
	status: {
		danger: 'orange',
	},

	headerHeight: '64px',
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
	light: responsiveFontSizes(lightTheme),
}

/*  // -------- PALETTE OLD 
paletteOld: {
  primary: {
    light: '#62a8d7',
    main: '#3b93cd',
    dark: '#29668f',
    contrastText: '#fff',
  },
  secondary: {
    light: '#757ce8',
    main: '#3abebc',
    dark: '#002884',
    contrastText: '#000',
  },
  secondary2: {
    light: '#757ce8',
    main: '#000',
    dark: '#002884',
    contrastText: '#000',
  },
  grey: {
    main: '#666666cf',
    dark: 'rgba(0, 0, 0, 0.74)',
  },
  almostBlack: {
    0: '#ffffff',
    100: '#fafafb',
    200: '#f1f1f5',
    300: '#e2e2ea',
    400: '#d5d5dc',
    500: '#b5b5be',
    600: '#92929d',
    700: '#696974',
    800: '#44444f',
    900: '#171725'
  },
  others: {
    tableHeaderBg: {
      main: '#9e9c9c14'
    }
  },
  success: {
    light: '#9bcf63',
    main: '#82c43c',
    dark: '#5b892a'
  },
  error: {
    light: '#fc7b7b',
    main: '#fc5a5a',
    dark: '#b03e3e'
  },
  warning: {
    light: '#ffab6e',
    main: '#ff974a',
    dark: '#b26933'
  },
  info: {
    light: '#73c3ff',
    main: '#50b5ff',
    dark: '#387eb2'
  },
}, */
